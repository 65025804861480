import React from 'react'
import CalloutFlashCard from './CalloutFlashCard'

export default function Flash({ messages = [], intent, className = "", message = "" }) {
  if (typeof message === "string" && message.length) {
    return (
      <CalloutFlashCard intent={intent} content={message} className={className} />
    )
  }
  if (Array.isArray(messages)) {
    if (messages.length) {
      const components= messages.map((message, index) => {
        return <p
          key={index}
          dangerouslySetInnerHTML={{ __html: message }}>
        </p>
      })
      return (
        <CalloutFlashCard intent={intent} className={className}>
          {components}
        </CalloutFlashCard>
      )
    }
  }
  return null
}
