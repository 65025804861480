import React from 'react'
import { Dialog as BpDialog } from "@blueprintjs/core"
import styled from "styled-components"

const DialogBody = styled.div`
  background: #f2f4f7;
  padding: 16px;
`

export default function Dialog({
  title,
  isOpen,
  onClose,
  children,
  canEscapeKeyClose = false,
  canOutsideClickClose = false
}) {
  return (
    <BpDialog
      isOpen={isOpen}
      onClose={onClose}
      className="base-dialog"
      title={title}
      canEscapeKeyClose={canEscapeKeyClose}
      canOutsideClickClose={canOutsideClickClose}
    >
      <DialogBody>
        {children}
      </DialogBody>
    </BpDialog>
  )
}
