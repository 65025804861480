import React, { memo } from "react";
import Styled from "styled-components";
import { Tooltip } from "@blueprintjs/core";

import { testAttributeGenerator } from "../utils/common";

const HOVER_DELAY = 600;

export const ColumnDisplayOverrideWrapper = Styled.div`
  max-width: ${(props) => props.width};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default memo(function EllipsisWrapper({ width, name, tooltipContent }) {
  return (
    <div className="ellipsis-wrapper">
      <Tooltip
        position="top"
        content={tooltipContent ? tooltipContent : name}
        hoverOpenDelay={HOVER_DELAY}
      >
        <ColumnDisplayOverrideWrapper
          {...testAttributeGenerator("name", name)}
          width={width}
        >
          {name}
        </ColumnDisplayOverrideWrapper>
      </Tooltip>
    </div>
  );
});