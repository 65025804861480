import { useState } from "react"

const useToggleState = (defaultValue = false) => {
  const [currentState, toggleCurrentState] = useState(defaultValue)
  return [
    currentState,
    toggleCurrentState
  ]
}

export default useToggleState