import React from "react";
import { Tooltip } from "@blueprintjs/core";
import CopyToClipboard from "react-copy-to-clipboard";

import Button from "./Button";
import TagalysIcon from "./icons/TagalysIcon";
import { ICONS } from "../utils/icons";

const CopiedMessage = () => (
  <div className="center">
    <TagalysIcon icon={ICONS.COPY} className="mr-sm" />
    <span>Copied to clipboard.</span>
  </div>
);

const ClickToCopyMessage = ({ resourceType }) => (
  <div className="center">
    <TagalysIcon icon={ICONS.COPY} className="mr-sm" />
    <span>Click to copy the {resourceType}.</span>
  </div>
);

const CopyButton = ({ resourceType, resource, handleCopy, isCopied }) => {
  return (
    <Tooltip
        content={isCopied ? <CopiedMessage /> : <ClickToCopyMessage resourceType={resourceType} />}
        intent={isCopied ? "success" : "none"}
        position="top"
      >
    <CopyToClipboard text={resource} onCopy={handleCopy}>
        <Button
          icon={<TagalysIcon icon={ICONS.COPY}/>}
          iconSize={12}
          intent={isCopied ? "success" : "none"}
          outlined="true"
          small={true}
          label={isCopied ? "Copied" : "Copy to clipboard"}
        />
    </CopyToClipboard>
      </Tooltip>
  );
};

export default CopyButton;
