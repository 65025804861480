import React, { useEffect } from 'react'

import { apiGet } from '../api'
import useAuthorisationData from "../../shared/hooks/useAuthorisationData";
import useAppData from "../../shared/hooks/useAppData";

export default function SecureAreasContainer(props) {
  const { requires_authorization } = useAppData("authorization")
  const { toggleDialogState } = useAuthorisationData()
  // useEffect(() => {
  // }, [window.location.pathname])
  
  useEffect(() => {
    if (requires_authorization) {
      toggleDialogState(true)
    }
  }, [])

  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  )
}
