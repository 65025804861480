
import React from 'react'
import { Card as BpCard } from '@blueprintjs/core'
export default function Card({children, className}) {
  return (
    <BpCard
      elevation='0'
      className={className}
    >
      {children}
    </BpCard>
  )
}
