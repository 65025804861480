import React, { useState, Fragment, useEffect } from "react";

import ConfirmationPrompt from "../prompts/ConfirmationPrompt";
import { ICONS } from "../utils/icons";

export default React.memo(() => {
  let [alertOpen, setAlertOpen] = useState(false)
  let [redirectUrl, setRedirectUrl] = useState('')
  let [redirectionCause, setRedirectionCause] = useState('Current session has expired. ')
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    document.addEventListener("session-expired", handleEventListener)
  }, [])

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      handleRedirect()
    }
  }, [seconds]);

  const handleRedirect = () => {
    window.open(redirectUrl, "_self")
  }
  //need to confirm about redirection url
  const handleEventListener = (event) => {
    if(event.detail.hasOwnProperty('redirectionCause')){
      setRedirectionCause(event.detail.redirectionCause)
    }
    setAlertOpen(!alertOpen)
    setSeconds(10)
    setRedirectUrl(`${window.location.origin}/users/sign_in`)
  }
  return (
    <Fragment>
      <ConfirmationPrompt
        icon={ICONS.INFO_CIRCLE}
        isOpen={alertOpen}
        confirmButtonText="Log out"
        onConfirm={() => { handleRedirect() }}
        title={`${redirectionCause} You will be redirected to the login page in ${seconds} seconds.`}
        enforceConfirmAction={true}
      />
    </Fragment>
  );
});
