import React from "react";
import styled from "styled-components";
import { Intent } from "@blueprintjs/core";

import ConditionalRenderer from "./ConditionalRenderer";
import TagalysIcon from "./icons/TagalysIcon";

import { PILL_SHADES, SIZE } from "../utils/constants";

const StyledPill = styled.div`
  display: flex;
  height: ${(props) => props.dimensions.height};
  padding: ${(props) => props.dimensions.padding};
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  min-width: 30px;
  border: 1px solid ${(props) => props.colors.borderColor};
  background: ${(props) => props.colors.background};
  color: ${(props) => props.colors.color};
  font-size: ${(props) => props.dimensions.fontSize};
  font-weight: 600;
  line-height: ${(props) => props.dimensions.lineHeight};
  white-space: nowrap;
  .tagalys-icon-wrapper{
    path {
      stroke: ${(props) => props.colors.color};
    }
  }
`;

const noneIntentShadeColor = {
  [PILL_SHADES.LIGHT]: {
    background: "#F9FAFB",
    borderColor: "#D0D5DD",
  },
  [PILL_SHADES.DARK]: {
    background: "#D0D5DD",
    borderColor: "#475467",
  },
};

const pillDimensionMap = {
  [SIZE.XS]: {
    height: "18px",
    padding: "0 8px",
    fontSize: "12px",
    lineHeight: "18px",
  },
  [SIZE.S]: {
    height: "20px",
    padding: "0 8px",
    fontSize: "12px",
    lineHeight: "18px",
  },
  [SIZE.M]: {
    height: "24px",
    padding: "0 10px",
    fontSize: "13px",
    lineHeight: "18px",
  },
  [SIZE.L]: {
    height: "30px",
    padding: "0 10px",
    fontSize: "14px",
    lineHeight: "20px",
    iconSize: 18,
  },
};

const intentColorMapping = {
  [Intent.NONE]: {
    background: "#E4E7EC",
    color: "#344054",
    borderColor: "#D0D5DD",
  },
  [Intent.PRIMARY]: {
    background: "#A8D3FA",
    color: "#363F72",
    borderColor: "#83B0D9",
  },
  [Intent.SUCCESS]: {
    background: "#CDEFA2",
    color: "#2B5314",
    borderColor: "#A9C683",
  },
  [Intent.WARNING]: {
    background: "#FFE8C0",
    color: "#B54708",
    borderColor: "#E0B37B",
  },
  [Intent.DANGER]: {
    background: "#FECDCA",
    color: "#B42318",
    borderColor: "#E08B8B",
  },
};

const isEmpty = (text) => {
  return ((text === undefined) || (text === null) || (text === ""));
};

const Pill = ({
  children,
  text,
  leftIcon,
  size = SIZE.M,
  intent = Intent.NONE,
  shade,
  rightIcon,
  ...remainingProps
}) => {
  const dimensions = pillDimensionMap[size];
  let colors = intentColorMapping[intent];

  if (shade && intent === Intent.NONE) {
    colors = { ...colors, ...noneIntentShadeColor[shade] };
  }

  return (
    <StyledPill className="pill" dimensions={dimensions} colors={colors} {...remainingProps}>
      <ConditionalRenderer condition={leftIcon}>
        <TagalysIcon
          icon={leftIcon}
          className={"mr-4"}
          size={dimensions.iconSize}
        />
      </ConditionalRenderer>
      {isEmpty(text) ? children : text}
      <ConditionalRenderer condition={rightIcon}>
        <TagalysIcon
          icon={rightIcon}
          className="ml-4"
          size={dimensions.iconSize}
        />
      </ConditionalRenderer>
    </StyledPill>
  );
};

export default Pill;