import React, { Fragment } from "react";

import Table from "./Table";
import SearchInput from "../SearchInput";
import Pagination from "../Pagination";

// BULK SELECTION - NOT SUPPORTED
// FILTER - NOT SUPPORTED
// SOCKET - NOT SUPPORTED

const List = React.memo(
  ({
    searchable = true,
    searchPlaceHolder = "Search here",
    list,
    columnDisplayOverride,
    nonIdealStateProps,
    canSetParamsToUrl,
    onSortChange
  }) => {
    const { data, search, pagination } = list;

    return (
      <Fragment>
        {searchable ? (
          <SearchInput
            searchValue={search.data.query}
            loading={search.data.loading}
            changeInSearch={search.handlers.changeInSearch}
            clearSearch={search.handlers.clearSearch}
            className="search-input mb-15"
            searchableLabel={searchPlaceHolder}
            large={false}
            canSetParamsToUrl={canSetParamsToUrl}
          />
        ) : null}
        <div className="table-wrapper">
          <div className="table-container">
            <Table
              rows={data.list.items}
              headers={data.list.headers}
              loading={data.list.loading}
              columnDisplayOverride={columnDisplayOverride}
              canSetParamsToUrl={canSetParamsToUrl}
              nonIdealStateProps={nonIdealStateProps}
              onSortChange={onSortChange}
            />
          </div>
        </div>
        <div className="pagination-container mt-10">
          <Pagination
            currentPage={pagination.data.currentPage}
            totalPages={pagination.data.totalPages}
            perPage={pagination.data.perPage}
            onPageChange={pagination.handlers.onPageChange}
            canSetParamsToUrl={canSetParamsToUrl}
          />
        </div>
      </Fragment>
    );
  }
);

export default List;