import React, { memo, Fragment } from "react";
import { Switch, Route } from "react-router-dom";

import AuthenticateUser from "./AuthenticateUser"
import AuthorizeUser from "./AuthorizeUser"
import ResetPassword from "./ResetPassword"
import TwoFactorScreen from "./TwoFactorScreen"
import EditPassword from "./EditPassword"
import UnlockUser from "./UnlockUser"
import TagalysBanner from "./TagalysBanner"
import TwoFactorScreenForAuthProvider from "./TwoFactorScreenForAuthProvider"
import * as Styled from "../shared/styles/styled"
import FlashCard from "../shared/components/FlashCard"
import "./style.scss"

export default memo(function Router(props) {
  return (
    <Fragment>
      <Switch>
        <Route path={[
          "/users/sign_in",
          "/session/authorize_user",
          "/users/password/new",
          "/users/password/edit",
          "/users/unlock",
          "/users/unlock/new",
          "/users/auth/:authentication_provider/callback"
        ]}>
          <Styled.Section>
            <Styled.Container>
              <TagalysBanner />
              <FlashCard />
              <Route exact path={"/users/sign_in"}>
                <AuthenticateUser {...props} />
              </Route>
              <Route exact path={"/users/password/new"}>
                <ResetPassword/>
              </Route>
              <Route exact path={"/users/password/edit"}>
                <EditPassword/>
              </Route>
              <Route exact path={"/users/unlock"}>
                <UnlockUser/>
              </Route>
              <Route exact path={"/users/unlock/new"}>
                <UnlockUser/>
              </Route>
              <Route exact path={"/session/authorize_user"}>
                <AuthorizeUser {...props}/>
              </Route>
              <Route exact path={"/users/auth/:authentication_provider/callback"}>
                <TwoFactorScreenForAuthProvider {...props}/>
              </Route>
            </Styled.Container>
          </Styled.Section>
        </Route>
      </Switch>
    </Fragment>
  )
});
