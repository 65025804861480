import { useContext } from "react"
import { AppContext } from "../contexts/AppContext"

const useAppData = (key = null) => {
  const { app_data } = useContext(AppContext)
  return key ?  app_data[key] : app_data
}

export const useClearAppDataByKey = () => {
  const { clearAppDataByKey } = useContext(AppContext)
  return (key) => {
    clearAppDataByKey(key)
  } 
}

export default useAppData