import { useState } from "react";

const usePopup = () => {
  const [isOpen, setPopupOpen] = useState(false);

  const open = () => {
    setPopupOpen(true);
  };

  const close = () => {
    setPopupOpen(false);
  };

  const toggle = () => {
    if (isOpen) {
      close();
    } else {
      open();
    }
  };

  return {
    isOpen,
    close,
    open,
    toggle
  };
};

export default usePopup;