import { useImmer } from "use-immer";

const useSearch = () => {
  const [resource, setResourceState] = useImmer({
    search: {
      query: "",
      loading: false,
    },
  });

  const setSearchLoading = (loading) => {
    setResourceState((draft) => {
      draft.search.loading = loading;
    });
  };

  const updateSearchQuery = (query) => {
    setResourceState((draft) => {
      draft.search.query = query;
    });
  };

  const changeInSearch = (query) => {
    updateSearchQuery(query);
  };

  const clearSearch = () => {
    updateSearchQuery("");
  };

  return {
    clearSearch,
    changeInSearch,
    setSearchLoading,
    resource,
  };
};

export default useSearch;
