import React from "react";
import { Button } from "@blueprintjs/core";
import TagalysIcon from "./icons/TagalysIcon";

export const Intent = {
  PRIMARY: "primary",
  SUCCESS: "success",
  WARNING: "warning",
};

const CalloutFlashCard = ({
  content,
  leftIcon,
  intent = "primary",
  rightAction,
  className = "",
  small = false,
  children,
}) => {
  let classNames = ["flash-card", "flex", "space-between", "align-items-start", intent, className];
  if (small) {
    classNames.push("small");
  }
  return (
    <div
      className={classNames.join(" ")}
    >
      <div className="flex flex-gap-md align-items-start">
        {typeof leftIcon === "string" ? (
          <TagalysIcon size={small ? 16 : 20} icon={leftIcon} />
        ) : (
          leftIcon
        )}
        <div className="content-body">
          {typeof content === "string" ? (
            <p className="m-0">{content}</p>
          ) : (
            content
          )}
        </div>
        {children}
      </div>
      {rightAction && (
        <Button
          minimal
          small
          id={'flash-card-action'}
          className={`flash-card-${intent}`}
          variant="outline"
          loading={rightAction.loading}
          size="sm"
          onClick={rightAction.onClick}
        >
          {rightAction.text}
        </Button>
      )}
    </div>
  );
};

export default CalloutFlashCard;