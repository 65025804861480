import zxcvbn from 'zxcvbn'

export default class PasswordStrengthChecker {
  constructor(password) {
    this.password = password;
    this.passwordEstimation = zxcvbn(password)
  }

  isValid() {
    return this.score() >= 3
  }

  suggestions() {
    return this.passwordEstimation.feedback.suggestions
  }

  hasSuggestions() {
    return this.suggestions().length > 0
  }

  intent() {
    return this.getInfoByScore(this.score()).intent
  }

  label() {
    if (this.isValid()) {
      return undefined
    }
    return "This password is insecure. Please choose a more secure password."
  }

  score() {
    return this.passwordEstimation.score
  }

  safePercentage() {
    return (this.safeValueInDecimal())*100
  }

  safeValueInDecimal() {
    return (this.score()/4)
  }

  getInfoByScore(score) {
    const scoreToInfoMap = {
      0: {
        id: "too_guessable",
        label: "Your password looks easily predictable. Please try a better password.",
        intent: "danger"
      },
      1: {
        id: "very_guessable",
        label: "Your password looks easily predictable. Please try a better password.",
        intent: "danger"
      },
      2: {
        id: "somewhat_guessable",
        label: "Your password looks fairly predictable. Please try a better password.",
        intent: "warning"
      },
      3: {
        id: "safely_unguessable",
        label: "Your password looks almost secured. Please improve your password further.",
        intent: "success"
      },
      4: {
        id: "very_unguessable",
        label: "Your password looks secure. Thank you.",
        intent: "success"
      }
    }
    return scoreToInfoMap[score]
  }
}