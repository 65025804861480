import React, { Fragment }from 'react'
import SignIn from './SignIn'


export default function AuthorizeUser(props) {
  const getParams = (formDetails) => {
    return {
      user: {
        email: formDetails.email,
        password: formDetails.password,
      }
    }
  }

  const getOtpParams = ({value, secondFactorMethod}) => {
    return {
      user: {
        otp_attempt: value,
        second_factor_method: secondFactorMethod,
      }
    }
  }
  
  const config = {
    showTrustDevice: false,
    firstFactorPath: "users/sign_in",
    twoFactorPath: "session/authenticate_with_two_factor",
    getParams: getParams,
    getOtpParams: getOtpParams
  }
  
  const handleFirstFactorResponse = (response) => {
    if (response.locked) {
      window.location.href = `${window.location.origin}${response.redirect_path}`
      return
    }
    if (response.authorized) {
      window.close()
    }
  }

  const handleTwoFactorResponse = (response) => {
    if (response.locked) {
      window.location.href = `${window.location.origin}${response.redirect_path}`
      return
    }
    if (response.authorized) {
      window.close()
    }
  }
  return (
    <Fragment>
      <SignIn
        {...props}
        handleFirstFactorResponse={handleFirstFactorResponse}
        handleTwoFactorResponse={handleTwoFactorResponse}
        config={config}
      />
    </Fragment>
  )
}
