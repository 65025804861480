import React, { Fragment, useRef, useEffect } from "react";
import ProfilePanel from "./components/ProfilePanel";
import PasswordPanel from "./components/PasswordTab";
import SessionPanel from "./components/SessionTab";
import AuthenticationPanel from "./components/AuthenticationTab";
import TwoFactorAuthPanel from "./components/TwoFactorAuthTab";
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import SettingsNav from "./components/SettingsNav";
import { InnerContainer } from "./components/StyledComponents";
import SecureAreasContainer from "../shared/components/SecureAreasContainer"
import './style.scss';
import '../shared/style.scss';

export default React.memo(() => {
  const { path } = useRouteMatch()
  return (
    <Fragment>
      <Switch>
        <Route path={[path]}>
          <div className={`settings-container`}>
            <SettingsNav />
            <InnerContainer>
              <SecureAreasContainer>
                <Route exact path={`${path}/profile`} component={ProfilePanel} />
                <Route exact path={`${path}/security/sessions`} component={SessionPanel} />
                <Route exact path={`${path}/security/change_password`} component={PasswordPanel} />
                <Route exact path={`${path}/security/authentication`} component={AuthenticationPanel} />
                <Route exact path={`${path}/security/two_factor_authentication`} component={TwoFactorAuthPanel} />
              </SecureAreasContainer>
            </InnerContainer>
          </div>
        </Route>
      </Switch>
    </Fragment>
  );
});
