import React from "react";

const ConditionalRenderer = React.memo(({
  FallBack = null,
  condition, // Must be boolean
  children
}) => {
  if(condition){
    return children
  }
  return FallBack
})

export default ConditionalRenderer