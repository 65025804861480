import React,{ useState } from 'react'

import * as Styled from "../shared/styles/styled"
import ChangePassword from '../shared/components/ChangePassword'
import Flash from '../shared/components/Flash'
import "../shared/style.scss"
import { apiPut } from '../shared/api'

export default function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})

  const handleFormSubmit = async (formDetails) => {
    const urlParams = new URLSearchParams(location.search);
    const { password, confirmation_password } = formDetails
    if (password === confirmation_password) {
      setLoading(true)
      const params = {
        user: {
          reset_password_token: urlParams.get("reset_password_token"),
          password: password,
          confirmation_password: confirmation_password
        }
      }
      const res = await apiPut("users/password", params)
      if (res.updated) {
        window.location.href = `${window.location.origin}${res.path}`
      } else if(res.path){
        window.location.href = `${window.location.origin}${res.path}`
        return
      }
      if (res.errors) {
        setErrors(res.errors)
      }
      setLoading(false)
    }
  }
  return (
    <div className="flex-1">
      <ChangePassword
        onSubmit={handleFormSubmit}
        Header={() => <Styled.Heading>Create / Change your password</Styled.Heading>}
        loading={loading}
        errors={errors}
        setErrors={setErrors}
        containerClass={"edit-password-container"}
      />
    </div>
  )
}
