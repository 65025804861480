import React, { memo, useEffect } from 'react'
import Pusher from "pusher-js"
import { createContext } from 'react';

export const SocketContext = createContext()
export const SocketProvider = (props) => {
  const pusher = new Pusher(process.env['pusher.key'], {
    cluster: process.env['pusher.cluster'],
    authEndpoint: props.authEndPoint,
    auth: {
      headers: {
        'X-CSRF-TOKEN': document
        .querySelector("meta[name='csrf-token']")
        .getAttribute("content")
      }
    }
  });
  return (
    <SocketContext.Provider  value={pusher}>
      {props.children}
    </SocketContext.Provider>
  )
}