import { useEffect, useContext} from 'react'
import { SocketContext }  from './SocketProvider';

export default function SocketConsumer(props) {
  const pusher = useContext(SocketContext)
  const { channel:channelName, events, event, onNotificationRecieved, globalEvent} = props
  useEffect(() => {
    const channel = pusher.subscribe(channelName);
    if(globalEvent){
      channel.bind_global(onNotificationRecieved)
    }else{
      if(events){
        events.map((ev)=>{
          channel.bind(ev.event_type, (data) => {
            console.log(data)
            ev.callback(data)
          });
        })
      }else{
        channel.bind(event, (data) => {
          onNotificationRecieved(data)
        });
      }
    }
    return () => {
      if(events){
        events.map((ev)=>{
          channel.unbind(ev.event_type);
        })
      }else{
        channel.unbind(event)
      }
    }
  },[channelName])
  return null
}
