import React from 'react'

import ConfirmationPrompt from '../shared/prompts/ConfirmationPrompt'
import { ICONS } from '../shared/utils/icons'

const secondFactorMethodToLabelMap = {
  "backup_code": "Backup code",
  "otp": "OTP"
}

export default function ReSignInPrompt({
  isOpen,
  promptDetails,
  onConfirm
}) {
  const { second_factor_method: secondFactorMethod  } = promptDetails

  return (
    <ConfirmationPrompt
      icon={ICONS.CLOCK}
      isOpen={isOpen}
      confirmButtonText="Close"
      onConfirm={onConfirm}
      title={`It's been a while since you entered ${secondFactorMethodToLabelMap[secondFactorMethod]}.`}
      description={"Please, enter your credentials again to Log In."}
    />
  )
}
