import React from 'react'
import { useImmer } from 'use-immer'

export const AppContext = React.createContext()

export default function AppContextProvider(props) {
  const [appData, setAppData] = useImmer(props.app_data)

  const clearAppDataByKey = (key) => {
    setAppData((draftState) => {
      if (draftState.hasOwnProperty(key)) {
        delete draftState[key]
      }
    })
  }
  return (
    <AppContext.Provider value={{
      app_data: appData,
      clearAppDataByKey: clearAppDataByKey
    }}>
      {props.children}
    </AppContext.Provider>
  )
}
