import React, { useEffect, useState, useCallback, Fragment } from "react";
import { OverlayToaster } from "@blueprintjs/core";
import useAppData from "../hooks/useAppData";
import SocketConsumer from "../SocketConsumer"
import useAuthorisationData from "../hooks/useAuthorisationData";
import TagalysIcon from "./icons/TagalysIcon";
import { ICONS } from "../utils/icons";
import ConfirmationPrompt from "../prompts/ConfirmationPrompt";

const toaster = OverlayToaster.create({
  position: 'top'
})

export default function RequireAuthenticationDialog(props) {
  const [detail, setDetail] = useState({});
  const { session_channel } = useAppData("socket_channels")
  const { url: authorization_url} = useAppData("authorization")

  const { isDialogOpen, toggleDialogState: setDialogState } = useAuthorisationData()

  useEffect(() => {
    document.addEventListener("forbidden_error", handleForbiddenError);
  }, []);

  const openDialog = () => {
    setDialogState(true);
  };

  const closeDialog = () => {
    setDialogState(false);
  };

  const handleForbiddenError = (evt) => {
    openDialog();
    setDetail(evt.detail);
  };

  const handleDialogClose = useCallback(async () => {
    if (detail.callback) {
      await detail.callback()
    }
    toaster.show({
      message: "Thank you for authenticating. You may now proceed with your session.",
      intent: "success",
      icon: <TagalysIcon icon={ICONS.CHECK} />
    })
  }, [detail])

  return (
    <Fragment>
     <SocketConsumer
        channel={session_channel}
        event={"updated/authenticated_at"}
        onNotificationRecieved={(data) => {
          closeDialog()
        }}
      />
      <ConfirmationPrompt
        isOpen={isDialogOpen}
        onClosing={handleDialogClose}
        icon={ICONS.INFO_CIRCLE}
        title={"Please log in again to access this secured section."}
        confirmButtonText={"Log in"}
        onConfirm={() => window.open(authorization_url, "_blank")}
        enforceConfirmAction={true}
      />
    </Fragment>
  );
}
