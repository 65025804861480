export const PILL_SHADES = {
  LIGHT: "light",
  DARK: "dark",
};

export const SIZE = {
  XS: "xs",
  S: "s",
  M: "m",
  L: "l",
};