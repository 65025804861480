import React from 'react'
import { FormGroup, InputGroup } from '@blueprintjs/core'
import { testAttributeGenerator } from '../utils/common'

export default function EmailInput({
  value,
  onChange,
  hasErrors,
  helperText = undefined
}) {
  return (
    <FormGroup
      label="Email"
      intent={hasErrors ? "danger" : undefined}
      helperText={helperText}
    >
      <InputGroup
        id="email"
        type="email"
        placeholder="Enter email"
        onChange={onChange}
        value={value}
        intent={hasErrors ? "danger" : undefined}
        {...testAttributeGenerator("sign-in-details", "email")}
      />
  </FormGroup>
  )
}
