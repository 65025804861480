import React, { Fragment } from "react";
import { Card } from "@blueprintjs/core";
import { CardItem } from "./StyledComponents";

export default React.memo(({
  className = 'security-card',
  title,
  rightElement = null,
  body,
  footer = null,
}) => {
  return (
    <Fragment>
      <Card className={className}>
        <CardItem className="card-header" >
          <div>{title}</div>
          {rightElement}
        </CardItem>
        {body}
        {footer}
      </Card>
    </Fragment>
  );
});
