import React from 'react'
import { ICONS_SVG_MAP } from '../../utils/icons'
import styled from 'styled-components'

export const StyledIcon = styled.span`
  display: inline-block;
  vertical-align: text-bottom;
  svg {
    display: block;
  }
`

const intentMap = {
  danger: "#B42318",
  none: "#344054",
  warning: "#B54708",
  primary: "#363F72",
}

const TagalysIcon = ({ icon, size = 14, className = "", intent = "none", stroke, ...remainingProps }) => {
  const IconComponent = ICONS_SVG_MAP[icon]
  const strokeColor = (stroke ? stroke : intentMap[intent]);

  if(!IconComponent) return null;

  return (
    <StyledIcon className={`tagalys-icon-wrapper icon-${icon} ${className}`} {...remainingProps}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 14 14"
        fill="none"
      >
        <IconComponent stroke={strokeColor} />
      </svg>
    </StyledIcon>
  )
}

export default TagalysIcon;