export function getQueryString(o) {
  function iter(o, path) {
    if (Array.isArray(o)) {
      o.forEach(function (a) {
        iter(a, path + "[]");
      });
      return;
    }
    if (o !== null && typeof o === "object") {
      Object.keys(o).forEach(function (k) {
        iter(o[k], path + "[" + k + "]");
      });
      return;
    }
    if (![null, undefined].includes(o)) {
      data.push(path + "=" + encodeURIComponent(o));
    }
  }

  var data = [];
  Object.keys(o).forEach(function (k) {
    iter(o[k], k);
  });
  return data.join("&");
}

export const getValuesFromObject = (obj) => {
  let values = [];
  for (let key in obj) {
    values.push(obj[key])
  }
  return values;
}

//  TODO: Go through debounce documentation and go through the function, else use lodash lib directly
const root = false;

export function debounce(func, wait, options) {
  let lastArgs,
    lastThis,
    maxWait,
    result,
    timerId,
    lastCallTime

  let lastInvokeTime = 0
  let leading = false
  let maxing = false
  let trailing = true

  // Bypass `requestAnimationFrame` by explicitly setting `wait=0`.
  const useRAF = false

  if (typeof func !== 'function') {
    throw new TypeError('Expected a function')
  }
  wait = +wait || 0
  if (typeof(options) === 'object') {
    leading = !!options.leading
    maxing = 'maxWait' in options
    maxWait = maxing ? Math.max(+options.maxWait || 0, wait) : maxWait
    trailing = 'trailing' in options ? !!options.trailing : trailing
  }

  function invokeFunc(time) {
    const args = lastArgs
    const thisArg = lastThis

    lastArgs = lastThis = undefined
    lastInvokeTime = time
    result = func.apply(thisArg, args)
    return result
  }

  function startTimer(pendingFunc, wait) {
    if (useRAF) {
      root.cancelAnimationFrame(timerId)
      return root.requestAnimationFrame(pendingFunc)
    }
    return setTimeout(pendingFunc, wait)
  }

  function cancelTimer(id) {
    if (useRAF) {
      return root.cancelAnimationFrame(id)
    }
    clearTimeout(id)
  }

  function leadingEdge(time) {
    // Reset any `maxWait` timer.
    lastInvokeTime = time
    // Start the timer for the trailing edge.
    timerId = startTimer(timerExpired, wait)
    // Invoke the leading edge.
    return leading ? invokeFunc(time) : result
  }

  function remainingWait(time) {
    const timeSinceLastCall = time - lastCallTime
    const timeSinceLastInvoke = time - lastInvokeTime
    const timeWaiting = wait - timeSinceLastCall

    return maxing
      ? Math.min(timeWaiting, maxWait - timeSinceLastInvoke)
      : timeWaiting
  }

  function shouldInvoke(time) {
    const timeSinceLastCall = time - lastCallTime
    const timeSinceLastInvoke = time - lastInvokeTime

    // Either this is the first call, activity has stopped and we're at the
    // trailing edge, the system time has gone backwards and we're treating
    // it as the trailing edge, or we've hit the `maxWait` limit.
    return (lastCallTime === undefined || (timeSinceLastCall >= wait) ||
      (timeSinceLastCall < 0) || (maxing && timeSinceLastInvoke >= maxWait))
  }

  function timerExpired() {
    const time = Date.now()
    if (shouldInvoke(time)) {
      return trailingEdge(time)
    }
    // Restart the timer.
    timerId = startTimer(timerExpired, remainingWait(time))
  }

  function trailingEdge(time) {
    timerId = undefined

    // Only invoke if we have `lastArgs` which means `func` has been
    // debounced at least once.
    if (trailing && lastArgs) {
      return invokeFunc(time)
    }
    lastArgs = lastThis = undefined
    return result
  }

  function cancel() {
    if (timerId !== undefined) {
      cancelTimer(timerId)
    }
    lastInvokeTime = 0
    lastArgs = lastCallTime = lastThis = timerId = undefined
  }

  function flush() {
    return timerId === undefined ? result : trailingEdge(Date.now())
  }

  function pending() {
    return timerId !== undefined
  }

  function debounced(...args) {
    const time = Date.now()
    const isInvoking = shouldInvoke(time)

    lastArgs = args
    lastThis = this
    lastCallTime = time

    if (isInvoking) {
      if (timerId === undefined) {
        return leadingEdge(lastCallTime)
      }
      if (maxing) {
        // Handle invocations in a tight loop.
        timerId = startTimer(timerExpired, wait)
        return invokeFunc(lastCallTime)
      }
    }
    if (timerId === undefined) {
      timerId = startTimer(timerExpired, wait)
    }
    return result
  }
  debounced.cancel = cancel
  debounced.flush = flush
  debounced.pending = pending
  return debounced
}

export const getParamsAsObject = (query) => {
  if (query.length) {
    query = query.substring(query.indexOf("?") + 1);
    let regex = /([^&=]+)=?([^&]*)/g;
    let decodeRE = /\+/g;
    let decode = function (str) {
      return decodeURIComponent(str.replace(decodeRE, " "));
    };
    let params = {}, e;
    while ((e = regex.exec(query))) {
      var k = decode(e[1]), v = decode(e[2]);
      if (k.substring(k.length - 2) === "[]") {
        k = k.substring(0, k.length - 2);
        (params[k] || (params[k] = [])).push(v);
      } else params[k] = v;
    }
    var assign = function (obj, keyPath, value) {
        var lastKeyIndex = keyPath.length - 1;
        for (var i = 0; i < lastKeyIndex; ++i) {
            var key = keyPath[i];
            if (!(key in obj))
                obj[key] = {}
            obj = obj[key];
        }
        obj[keyPath[lastKeyIndex]] = value;
    }
    for (let prop in params) {
      var structure = prop.split("[");
      if (structure.length > 1) {
        let levels = [];
        structure.forEach(function (item, i) {
          var key = item.replace(/[?[\]\\ ]/g, "");
          levels.push(key);
        });
        assign(params, levels, params[prop]);
        delete params[prop];
      }
    }
    return params;
  } else {
    return {};
  }
};

const isValidAttribute = (attribute) => {
  // * REGEX to allow Alphanumeric, - , _, space.
  // * NOTE: Setting unicode characters(eg: Arataki™) as attribute would lead to "Failed to execute 'setAttribute' on 'Element'"
  const regex = new RegExp(/^[a-z\d\-_\s]+$/i);
  // * test(): The test() method executes a search for a match between a regular expression and a specified string. Returns true or false
  return regex.test(attribute)
}

export const testAttributeGenerator = (attributeSuffix, value) => {
  let attribute = {}
  if(isValidAttribute(attributeSuffix)){
    attribute[`data-cy-tagalys-${attributeSuffix}`] = value
    return attribute
  }
  return attribute
}

export const pageCalculator = (c, m) => {
  var current = c,
    last = m,
    delta = 2,
    left = current - delta,
    right = current + delta + 1,
    range = [],
    rangeWithDots = [],
    l;
  for (let i = 1; i <= last; i++) {
    if (i == 1 || i == last || (i >= left && i < right)) {
      range.push(i);
    }
  }
  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push("...");
      }
    }
    if (i !== last || !rangeWithDots.filter((r) => r === "...").length) {
      rangeWithDots.push(i);
    }
    l = i;
  }
  return rangeWithDots;
};
