import React, { useEffect } from 'react'
import { FormGroup, InputGroup, Button} from '@blueprintjs/core'
import { useImmer } from 'use-immer'

import * as Styled from "../shared/styles/styled"
import "../shared/style.scss"
import { apiPost } from '../shared/api'
import useToggleState from '../shared/hooks/useToggleState'
import EmailInput from '../shared/components/EmailInput'
const UnlockUserDetails = () => {
  const [formDetails, setFormDetails] = useImmer({
    email: "",
    errors: []
  })
  const [loading, setLoading] = useToggleState()
  const { email, errors } = formDetails
  const handleFormSubmit = async () => {
    const { email } = formDetails
    setLoading(true)
    const res = await apiPost("users/unlock", {
      user: {
        email: email
      }
    })
    if (res) {
      if (res.password_instructions_sent && res.redirect_path) {
        window.location.href = `${window.location.origin}${res.redirect_path}`
        return
      }
      if (res.errors) {
        setFormDetails((draftState) => {
          draftState["errors"] = res.errors
        })
      }
    }
    setLoading(false)
  }
// Custom form state hooks
  const handleChangeInForm = (evt) => {
    evt.persist()
    setFormDetails((draftState) => {
      draftState[evt.target.id] = evt.target.value
      if (draftState.errors.length > 0) {
        draftState["errors"] = []
      }
    })
  }
  const hasErrorInEmail = errors.hasOwnProperty('email')
  return (
    <div className="flex-1">
      <Styled.Heading>
        Unlock your account
      </Styled.Heading>
      <EmailInput
        value={email}
        onChange={handleChangeInForm}
        hasErrors={hasErrorInEmail}
        helperText={hasErrorInEmail ? errors["email"] : undefined}
      />
      <Button
        className="w-100"
        loading={loading}
        intent="success"
        onClick={handleFormSubmit}
      >
        Send unlock instructions
      </Button>
    </div>
  )
}

export default function UnlockUser() {
  return (
    <UnlockUserDetails />
  )
}
