import { useImmer } from "use-immer";

const usePagination = () => {
  const [resource, setResourceState] = useImmer({
    pagination: {
      totalPages: null,
      perPage: null,
      currentPage: 1,
    }
  });

  const onPageChange = (selectedPage) => {
    setResourceState((draft) => {
      draft.pagination.currentPage = selectedPage;
    });
  };

  const setPagination = (paginationState) => {
    setResourceState((draft) => {
      draft.pagination = paginationState;
    });
  }

  return {
    onPageChange,
    resource,
    setPagination
  }
}

export default usePagination;