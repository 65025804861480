import React, { memo, Fragment, useEffect } from "react";
import { apiGet, apiPost } from '../../shared/api'
import { useImmer } from 'use-immer';
import { Heading, FlexColumn, Description } from "./StyledComponents";
import ChangePassword from "../../shared/components/ChangePassword"

const getParams = (state) => {
  const { new_password, confirmation_password } = state
  const params = {
    new_password: new_password,
    confirmation_password: confirmation_password,
  }
  return params
}

export default memo(function PasswordTab() {
  let [state, setState] = useImmer({
    errors: {},
    loading: false,
  })

  useEffect(() => {
    checkWhetherAreaIsAuthorized()
  }, [])

  const checkWhetherAreaIsAuthorized = async () => {
    await apiGet("user_session/can_access_secure_area", {
      secure_area: "change_password"
    })
  }

  const { current_password, errors, loading } = state
  const handlePasswordSave = async (formDetails) => {
    const { password, confirmation_password } = formDetails
    if (password === confirmation_password) {
      setState(draft => { draft.loading = true })
      let params = getParams({
        current_password,
        new_password: password,
        confirmation_password
      })
      const response = await apiPost('user/update_password', params);
      if (response) {
        if (response.saved) {
          const eventToDispatch = new CustomEvent("session-expired", {
            detail: {
              redirectionCause: "Your password has been changed successfully."
            }
          });
          document.dispatchEvent(eventToDispatch)
        }
        else {
          setState(draft => {
            draft.errors = response.errors
          })
        }
      }
      setState(draft => { draft.loading = false })
    }
  }


  const setErrors = (errors) => {
    setState(draft => {
      draft.errors = errors;
    })
  }

  return (
    <Fragment>
      <FlexColumn>
        <ChangePassword
          onSubmit={handlePasswordSave}
          containerClass={"change-password-container"}
          Header={() =>
            <Fragment>
              <Heading>Change Password</Heading>
              <Description>Changing your password will invalidate all logged in sessions.</Description>
            </Fragment>
          }
          loading={loading}
          errors={errors}
          setErrors={setErrors}
          formGroupClass="form-input"
        />
      </FlexColumn>
    </Fragment>
  )
})