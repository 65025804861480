import React from 'react'
import { AnchorButton, Icon } from '@blueprintjs/core'

const Button = ({
  icon,
  iconSize,
  intent,
  disabled,
  onClick,
  label,
  className,
  outlined,
  small,
  loading
}) => {
  return (
    <AnchorButton
      icon={<Icon icon={icon} size={iconSize} />}
      intent={intent}
      disabled={disabled}
      onClick={onClick}
      text={label}
      className={className}
      outlined={outlined}
      small={small}
      loading={loading}
    />
  )
}

export default Button