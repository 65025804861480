import React, { memo, useEffect, useRef, useState } from 'react'
import { Tooltip } from '@blueprintjs/core';

function TextOverflowTooltip({
  targetElement,
  disabled, // Tooltip's disabled state is handled by this component
  ...tooltipProps
}) {
  const targetRef = useRef(null);
  const [isOverflow, setIsOverflow] = useState(false);
  const renderCountRef = useRef(0);
  const [forceUpdate, setForceUpdate] = useState(0);

  useEffect(() => {
    // Track renders and force an update to trigger the effect
    renderCountRef.current += 1;
    setForceUpdate(renderCountRef.current); // Trigger re-render of Tooltip
  }, []);

  const checkTextOverflow = () => {
    if (targetRef.current) {
      setIsOverflow(targetRef.current.scrollWidth > targetRef.current.clientWidth);
    }
  };

  useEffect(() => {
    checkTextOverflow();
    window.addEventListener('resize', checkTextOverflow);
    return () => {
      window.removeEventListener('resize', checkTextOverflow);
    };
  }, [forceUpdate]);

  return (
    <Tooltip {...tooltipProps} disabled={!isOverflow}>
      {targetElement(targetRef)}
    </Tooltip>
  )
}

export default memo(TextOverflowTooltip)