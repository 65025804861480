import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "../../../../node_modules/@blueprintjs/core/lib/css/blueprint.css"
import "../../../../node_modules/@blueprintjs/icons/lib/css/blueprint-icons.css";
import AppData from "./shared/contexts/AppContext";
import AuthorizationData from "./shared/contexts/AuthorisationContext"
import SessionsRouter from "./sessions/Router"
import SettingsRouter from "./settings/Router";
import RequireAuthenticationDialog from "./shared/components/RequireAuthenticationDialog"
import SessionExpiredPopup from "./shared/components/SessionExpiredPopup"
import { SocketProvider } from "./shared/SocketProvider";
import ArchiveIndex from "./clients/archive/Index";

class RootRouter extends Component {

  render() {
    return (
      <AppData {...this.props}>
        <AuthorizationData>
          <SocketProvider
            authEndPoint={`${window.location.origin}/socket_auth`}
          >
            <RequireAuthenticationDialog {...this.props} />
            <SessionExpiredPopup />
            <Router>
              <Switch>
                <Route
                  path="/clients/archived"
                  component={(props) => {
                    return <ArchiveIndex {...props} />
                  }}
                />
                <Route
                  path={"/"}
                  component={(props) => {
                    return (
                      <Fragment>
                        <SessionsRouter {...props} />
                        <Route
                          path="/user/settings"
                          component={(props) => {
                            return (<SettingsRouter {...props} />)
                          }}
                        />
                      </Fragment>
                    );
                  }}
                />
              </Switch>
            </Router>
          </SocketProvider>
        </AuthorizationData>
      </AppData>
    );
  }
}


export default RootRouter;