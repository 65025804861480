import { useContext } from "react"
import { AuthorisationContext } from "../contexts/AuthorisationContext"

const useAuthorisationData = () => {
  const [isDialogOpen, toggleDialogState] = useContext(AuthorisationContext)
  return {
    isDialogOpen,
    toggleDialogState
  }
}

export default useAuthorisationData