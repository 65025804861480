import React, { useState} from 'react'
import styled from 'styled-components'
import { Button, OverlayToaster} from '@blueprintjs/core'

import * as Styled from "../shared/styles/styled"
import "../shared/style.scss"
import { apiPost } from '../shared/api'
import useToggleState from '../shared/hooks/useToggleState'
import { useHistory } from 'react-router-dom'
import Flash from '../shared/components/Flash'
import EmailInput from '../shared/components/EmailInput'
import { useClearAppDataByKey } from '../shared/hooks/useAppData'
import TagalysIcon from '../shared/components/icons/TagalysIcon'
import { ICONS } from '../shared/utils/icons'

const StyledNextStepsWrapper = styled.div`
  p {
    color: #344054;
  }
`

const toaster = OverlayToaster.create({
  position: 'top'
})

const ResetPasswordForm = ({ setShowNextSteps, setRedirectPath }) => {
  const [email, setEmail] = useState("")
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useToggleState()
  const clearAppDataByKey = useClearAppDataByKey()

  const handleResetPassword = async (evt) => {
    evt.preventDefault()
    setLoading(true)
    const res = await apiPost("users/password", {
      user: {
        email: email
      }
    })
    if (res) {
      if (res.sent_password_instructions) {
        clearAppDataByKey("flash_details")
        setShowNextSteps(true)
        setRedirectPath(res.redirect_path)
        return toaster.show({
          ...res.toast,
          icon: <TagalysIcon icon={res.toast.icon} size={20} />
      })
      }
      setErrors(res.errors)
    }
    setLoading(false)
  }

  const handleChangeInEmail = (evt)=>{
    setEmail(evt.target.value)
    if (errors.length > 0) {
      setErrors([])
    }
  }

  return (
    <div className="flex-1">
      <Styled.Heading>
        Create / Reset your password
      </Styled.Heading>
      <Flash messages={errors} intent="danger" className="mb-xl" />
      <form>
        <EmailInput
          onChange={handleChangeInEmail}
          value={email}
          hasErrors={errors.length}
        />
        <Button
          type="submit"
          className="w-100"
          loading={loading}
          intent="success"
          onClick={handleResetPassword}
        >
          Send password reset instructions
        </Button>
      </form>
    </div>
  )
}

const NextSteps = ({ redirectPath }) => {
  const history = useHistory()
  
  const handleLoginAction = () => {
    history.push(redirectPath)
  }

  return (
    <StyledNextStepsWrapper className="flex-1">
      <Styled.Heading className="center">
        <TagalysIcon icon={ICONS.CHECK_CIRCLE} className="mr-10" size={16} />
        Successfully sent
      </Styled.Heading>
      <p className="mt-10 text-center ">
        Check your inbox for the link to reset your password.
      </p>
      <Button
        className="mt-20 w-100"
        icon={<TagalysIcon icon={ICONS.LOG_IN} />}
        onClick={handleLoginAction}
        intent="primary"
      >
        Go to login page
      </Button>
    </StyledNextStepsWrapper>
  )
}

export default function ResetPassword() {
  const [showNextSteps, setShowNextSteps] = useState(false)
  const [redirectPath, setRedirectPath] = useState("")
  if (showNextSteps) {
    return <NextSteps redirectPath={redirectPath}/>
  }
  return <ResetPasswordForm
    setShowNextSteps={setShowNextSteps}
    setRedirectPath={setRedirectPath}
  />
}
