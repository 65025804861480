import React from "react";
import Styled from "styled-components";
import { Classes } from "@blueprintjs/core";

import EllipsisWrapper from "../EllipsisWrapper";

const Td = Styled.td`
  white-space: nowrap;
`;

const Column = React.memo(function Column(props) {
  const { row } = props;

  const getValueForDisplay = (row, header) => {
    const field = header.field;
    const value = props.rowIndex >= 0 ? row[field][props.rowIndex] : row[field];
    const { columnDisplayOverride } = props;

    if (columnDisplayOverride && columnDisplayOverride[field]) {
      return columnDisplayOverride[field](row, columnDisplayOverride.additionalProps);
    }
    if (typeof value === "boolean") {
      return value ? "Yes" : "No";
    }
    if (header.max_width) {
      return (
        <EllipsisWrapper
          name={value}
          width={header.max_width}
          tooltipContent={header.tooltip_content}
        />
      );
    }
    return value;
  };

  let tds = [];

  props.headers.map((header) => {
    if (row.hasOwnProperty(header.field)) {
      tds.push(
        <Td
          maxWidth={header.max_width}
          className={`row-${header.field}`}
          key={header.field}
        >
          <div className={`${props.loading ? `${Classes.SKELETON} element-loading` : ""}`}>
            {getValueForDisplay(row, header)}
          </div>
        </Td>
      );
    } else {
      tds.push(<Td key={header.field}></Td>);
    }
  });
  return tds;
});

export default Column;