import { Intent } from '@blueprintjs/core'
import React from 'react'
import ConfirmationPrompt from '../prompts/ConfirmationPrompt'

export default function ResourceDestroyAlert({
  isOpen = false,
  icon = "trash",
  cancelButtonText="Cancel",
  confirmButtonText = "Delete",
  handleCancel,
  handleConfirm,
  header="",
  children
}) {
  return (
    <ConfirmationPrompt
      cancelButtonText={cancelButtonText}
      confirmButtonText={confirmButtonText}
      icon={icon}
      intent={Intent.DANGER}
      isOpen={isOpen}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      title={header}
      description={children}
    />
  )
}
