import React, { useState } from "react";
import styled from "styled-components";
import {
  Dialog,
  InputGroup,
  OverlayToaster,
} from "@blueprintjs/core";

import Button from "../../shared/components/Button";
import CopyButton from "../../shared/components/CopyButton";
import TagalysIcon from "../../shared/components/icons/TagalysIcon";
import { ICONS } from "../../shared/utils/icons";

const toaster = OverlayToaster.create({
  position: "top",
});

const StyledDialog = styled.div`
  font-size: 15px;
  padding: 10px 20px 0px 20px;
  hr {
    margin: 10px 0px;
  }
`;

const UnArchiveConfirmationDialog = ({
  isOpen,
  onClose,
  handleConfirm,
  resourceName,
  resetResource,
  loading
}) => {
  const [clientName, setClientName] = useState("");
  const [isClientNameCopied, setIsClientNameCopied] = useState(false);

  const onConfirm = () => {
    if (clientName === resourceName) {
      handleConfirm();
      return;
    }
    toaster.show({
      message: "Invalid client name.",
      intent: "none",
      timeout: 3000,
      intent: "warning",
    });
    return;
  };

  const onReset = () => {
    setClientName("");
    setIsClientNameCopied(false);
    resetResource();
  };

  const handleCopy = () => {
    setIsClientNameCopied(true);
    toaster.show({
      message: "Client name copied to your clipboard.",
      intent: "none",
      timeout: 3000,
      icon: <TagalysIcon icon={ICONS.COPY} />
    });
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      onClosing={onReset}
      title={`Are you sure you want to Unarchive ${resourceName} ?`}
      className={"bg-white"}
      style={{ width: "800px" }}
    >
      <StyledDialog>
        <div className="mb-15">
          <div>
            <span>Once the account is unarchived</span>
            <ol className="mt-10">
              <li>The front-end APIs will be enabled</li>
              <li>All background processes will be restored (Account status will be set to Active: Yes)</li>
              <li>Client Users will be assigned their access</li>
            </ol>
          </div>
          <hr />
          <div>
            <span>Please type the client name to confirm your action</span>
            <div className="justify-end">
              <CopyButton
                resourceType={"Client name"}
                resource={resourceName}
                handleCopy={handleCopy}
                isCopied={isClientNameCopied}
              />
            </div>
            <InputGroup
              placeholder={resourceName}
              onChange={(event) => setClientName(event.target.value)}
              value={clientName}
              className="mt-5"
            />
          </div>
        </div>
        <div className="justify-end">
          <Button onClick={onClose} label={"Cancel"} />
          <Button
            intent={"primary"}
            onClick={onConfirm}
            disabled={!clientName.length}
            label={"Unarchive"}
            className={"ml-10"}
            loading={loading}
          />
        </div>
      </StyledDialog>
    </Dialog>
  );
};

export default UnArchiveConfirmationDialog;
