import React, { memo } from "react";
import styled from "styled-components";
import { AnchorButton, Dialog, Intent, Spinner, Tooltip } from "@blueprintjs/core";

import ConditionalRenderer from "../components/ConditionalRenderer";
import TagalysIcon from "../components/icons/TagalysIcon";
import { ICONS } from "../utils/icons";

const StyledBody = styled.div`
  font-size: 15px;
  .title {
    font-size: 17px;
    font-weight: bold;
  }
  .prompt-description {
    display: block
  }
  .cross-btn {
    height: 10px;
    margin: -10px -10px 0 0;
  }
  .button {
    white-space: nowrap;
  }
  .flex {
    display: flex;
    width: 100%;
  }
  .prompt-icon {
    background-color: #f2f4f7;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 12px;
    border-radius: 50%;
    &.intent-danger {
      background-color: #FEE4E2;
      svg * {
        stroke: #D92D20;
      }
    }
    &.intent-primary {
      svg * {
        stroke: #667085;
      }
    }
  }
`;

const defaultTitle = "Do you confirm that you wish to proceed?";

const Actions = ({
  intent,
  onConfirm,
  onCancel,
  loading,
  showCancelOption,
  disableConfirmButton,
  confirmButtonTooltipProps = {},
  AdditionalActions = null,
  confirmButtonText = "Continue",
  cancelButtonText = "Cancel",
}) => {
  return (
    <div
      className="flex mt-20"
    >
      <Tooltip {...confirmButtonTooltipProps}>
        <AnchorButton
          intent={intent}
          className="button"
          text={confirmButtonText}
          loading={loading}
          onClick={onConfirm}
          disabled={disableConfirmButton}
        />
      </Tooltip>
      {AdditionalActions}
      <ConditionalRenderer condition={showCancelOption} >
        <AnchorButton
          className="ml-10 button"
          text={cancelButtonText}
          onClick={onCancel}
        />
      </ConditionalRenderer>
    </div>
  );
};

export default memo(function ConfirmationPrompt({
  loading,
  onCancel,
  onConfirm,
  confirmButtonLoading,
  description,
  AdditionalActions,
  isOpen,
  cancelButtonText,
  confirmButtonText,
  disableConfirmButton,
  confirmButtonTooltipProps,
  onClosed,
  onClosing,
  enforceConfirmAction,
  width = "600px",
  usePortal = true,
  lazy = true,
  icon = ICONS.ALERT_TRIANGLE,
  title = defaultTitle,
  intent = Intent.PRIMARY,
}) {
  const isDescriptionComponent = !(description && typeof description === "string");
  const showCancelOption = !enforceConfirmAction;

  const style= {
    width,
    padding: "20px",
  }

  return (
    <Dialog
      isOpen={isOpen}
      style={style}
      usePortal={usePortal}
      shouldReturnFocusOnClose={false}
      onClose={onCancel}
      onClosed={onClosed}
      onClosing={onClosing}
      lazy={lazy}
    >
      <ConditionalRenderer
        condition={!loading}
        FallBack={<Spinner size={35} />}
      >
        <StyledBody>
          <div className="flex">
            <TagalysIcon
              icon={icon}
              size={24}
              intent={intent}
              className={`mr-20 prompt-icon intent-${intent}`}
            />
            <div className="w-100">
              <div
                className="title mb-5"
              >
                {title}
              </div>
              <ConditionalRenderer
                condition={isDescriptionComponent}
                FallBack={
                  <span
                    className="prompt-description"
                  >
                    {description}
                  </span>
                }
              >
                {description}
              </ConditionalRenderer>
              <Actions
                intent={intent}
                loading={confirmButtonLoading}
                showCancelOption={showCancelOption}
                confirmButtonText={confirmButtonText}
                cancelButtonText={cancelButtonText}
                disableConfirmButton={disableConfirmButton}
                AdditionalActions={AdditionalActions}
                confirmButtonTooltipProps={confirmButtonTooltipProps}
                onConfirm={onConfirm}
                onCancel={onCancel}
              />
            </div>
            <ConditionalRenderer condition={showCancelOption}>
              <AnchorButton
                minimal
                icon={<TagalysIcon icon={ICONS.X_CLOSE} size={16} />}
                className="cross-btn"
                onClick={onCancel}
              />
            </ConditionalRenderer>
          </div>
        </StyledBody>
      </ConditionalRenderer>
    </Dialog>
  );
});