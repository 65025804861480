import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { Icon, Tooltip } from "@blueprintjs/core";

import { getParamsAsObject, getQueryString } from "../../utils/common";

const Header = React.memo(({
  headers,
  onSortChange,
  canSetParamsToUrl,
  loading
}) => {
  const history = useHistory();

  const setParamsToUrl = (params) => {
    history.replace(`${history.location.pathname}?${getQueryString(params)}`);
  };

  const handleRouteOnSortChange = (selectedColumn, header) => {
    let params = getParamsAsObject(history.location.search);
    onSortChange(selectedColumn);
    if (header.sort_by !== null && header.field === selectedColumn) {
      if (header.allowed_sort_options.length > 1) {
        params['sort'] =  {
          field: header.field,
          direction: (header.sorted_by === header.allowed_sort_options[0]) ? (header.allowed_sort_options[1]) : (header.allowed_sort_options[0])
        }
      }
    }
    if (canSetParamsToUrl) {
      setParamsToUrl(params);
    }
  };

  const renderHeaderIcon = (isSelectedSortOrder, sortedBy) => {
    let sortedByIconName = "arrows-vertical";
    let iconClassName = "table-icon";
    if (isSelectedSortOrder) {
      sortedByIconName = sortedBy === "asc" ? "sort-asc" : "sort-desc";
      iconClassName = "table-icon table-icon-selected";
    }
    return (
      <Icon icon={sortedByIconName} iconSize={14} className={iconClassName} />
    );
  };

  const RenderHeaderLabelAndIcons = ({
    header,
    isSortable,
    isSelectedSortOrder,
    hasTooltip
  }) => {
    return header.label ? (
      <Fragment>
        <span className={`align-center`}>
          <span>
            <span>{header.label} </span>
          </span>
          {hasTooltip ? (
            <Tooltip
              className="inline-tooltip"
              content={header.tooltip}
              position="bottom"
            >
              <Icon
                className="ml-5"
                icon="info-sign"
                iconSize={12}
                color="#333"
              />
            </Tooltip>
          ) : null}
          <span style={{ marginLeft: 5 }}>
            {isSortable
              ? renderHeaderIcon(isSelectedSortOrder, header.sorted_by)
              : ""}
          </span>
        </span>
        {header.sub_label && (
          <div className="header-sub-label">{header.sub_label}</div>
        )}
      </Fragment>
    ) : null;
  };

  let ths = [];

  headers.map((header, index) => {
    const isSortable = header.allowed_sort_options;
    const isSelectedSortOrder = header.sorted_by ? header.sorted_by : false;
    const hasTooltip = header.hasOwnProperty('tooltip') && header.tooltip !== null;

    ths.push(
      <th
        style={{width: header.max_width}}
        className={`${
          isSortable ? "table-header-sortable" : ""
          } table-header ${ loading ? "text-fade" : "" }`}
        key={index}
        onClick={() => {
          isSortable ? handleRouteOnSortChange(header.field, header) : null;
        }}
      >
        <RenderHeaderLabelAndIcons
          header={header}
          isSortable={isSortable}
          isSelectedSortOrder={isSelectedSortOrder}
          hasTooltip={hasTooltip}
        />
      </th>)
  });
  return ths
});

export default Header;