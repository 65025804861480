import React from "react";

import Column from "./Column";

const getClassName = (row) => {
  let className = "";
  if (row.enabled === false) {
    className = "disabled";
  }
  if (row.processing === true) {
    className = className + " processing";
  }
  return className;
};

const Row = React.memo(function Row({
  row,
  headers,
  loading,
  className,
  columnDisplayOverride
}) {
  return (
    <tr data-resource-id={row.id} className={className}>
      <Column
        row={row}
        headers={headers}
        loading={loading}
        columnDisplayOverride={columnDisplayOverride}
      />
    </tr>
  );
});

const Rows = React.memo(function Rows({
  rows,
  headers,
  loading,
  columnDisplayOverride
}) {

  return rows.map((row, index) => {
    return (
      <Row
        key={index}
        row={row}
        headers={headers}
        loading={loading}
        className={getClassName(row)}
        columnDisplayOverride={columnDisplayOverride}
      />
    );
  });
});

export default Rows;