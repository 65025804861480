import React, { useCallback, useState } from 'react'
import { FormGroup, InputGroup, Button, Intent } from '@blueprintjs/core'
import { useImmer } from 'use-immer'

import * as Styled from "../styles/styled"
import "../style.scss"
import { apiPost } from '../api'
import PasswordStrengthChecker from "../utils/PasswordStrengthChecker"
import { debounce, getValuesFromObject } from '../utils/common'
import Flash from "./Flash"
import TagalysIcon from './icons/TagalysIcon'
import { ICONS } from '../utils/icons'

const BREACHED_PASSWORD = "breached_password"
const WEAK_PASSWORD = "weak_password"
const VALID_PASSWORD = "valid_password"
const BLANK_PASSWORD = "blank_password"

const ChangePassword = ({
  ctaLabel = "Change password",
  containerClass,
  errors = {},
  setErrors,
  loading = false,
  Header,
  children,
  onSubmit,
  formGroupClass = '',
}) => {
  const [formDetails, setFormDetails] = useImmer({
    password: "",
    confirmation_password: "",
  })
  const [pwnedValidatorResponse, setPwnedValidatorResponse] = useState({
    valid:  false,
    message: "",
    pwned_count: 0
  })
  const [passwordInputType, setPasswordInputType] = useState("password")
  const [isPwnedValidationInProgress, setPwnedValidationInProgress] = useState(false)
  const { password, confirmation_password } = formDetails
  const passwordStrengthChecker = new PasswordStrengthChecker(password)
  const isPasswordWeakOrBreached = (
    errors.hasOwnProperty("password_strength") ||
    errors.hasOwnProperty("password_breach")
  )
  const isPasswordStrengthWeak = (!passwordStrengthChecker.isValid())
  const isPwned = (!pwnedValidatorResponse.valid)
  const isPasswordPresent = password.length > 0
  const isSubmitDisabled = (
    loading ||
    isPasswordStrengthWeak ||
    isPwned ||
    isPwnedValidationInProgress ||
    !isPasswordPresent
  )
  const hasErrorInPassword = isPasswordWeakOrBreached
  
  const handleChangeInForm = (evt) => {
    evt.persist()
    setFormDetails((draftState) => {
      draftState[evt.target.id] = evt.target.value
    })
    if (Object.keys(errors).length > 0) {
      setErrors({})
    }
  }

  const pwnedpasswords = async (password) => {
    const response = await apiPost('password/pwned_validations', {
      password: password,
    })
    return response
  }

  const pwned = async (password) => {
    setPwnedValidationInProgress(true)
    const response = await pwnedpasswords(password)
    setPwnedValidationInProgress(false)
    if (response) {
      return response
    } else {
      return false
    }
  }

  const checkForPasswordBreach = useCallback(debounce(async (password) => {
    const response = await pwned(password)
    if (response) {
      setPwnedValidatorResponse(response)
    }
  }, 500), [])

  
  const handleChangeInPassword = async (evt) => {
    handleChangeInForm(evt)
    await checkForPasswordBreach(evt.target.value)
  }

  const getPasswordVulnerabilityStatus = () => {
    if (!isPasswordPresent) {
      return BLANK_PASSWORD
    }
    if (pwnedValidatorResponse.valid) {
      if (passwordStrengthChecker.isValid()) {
        return VALID_PASSWORD
      }
      return WEAK_PASSWORD
    }
    return BREACHED_PASSWORD
  }

  const Label = () => {
    return (
      <Styled.Flex className="justify-between align-center">
        <span>New password</span>
      </Styled.Flex>
    )
  }
  
  const RightElement = () => {
    const icon = ((passwordInputType === "password") ? ICONS.EYE : ICONS.EYE_OFF);

    return (
      <Button
        minimal
        icon={<TagalysIcon icon={icon} />}
        onClick={() => {
          if (passwordInputType === "text") {
            return setPasswordInputType("password")
          }
          setPasswordInputType("text")
        }}
      />
    )
  }
  
  const getHelperText = (inputFor) => {
    if (isPasswordWeakOrBreached) {
      return undefined
    }
    return errors[inputFor]
  }

  const vulnerabilityStatusToInfoMap = {
    [`${BREACHED_PASSWORD}`]: {
      tooltip_message: "This password has been exposed in multiple data breaches. Please choose a different password.",
      intent: "danger"
    },
    [`${WEAK_PASSWORD}`]: {
      tooltip_message: passwordStrengthChecker.label(),
      intent: "danger"
    },
    [`${VALID_PASSWORD}`]: {
      tooltip_message: undefined,
      intent: undefined
    },
    [`${BLANK_PASSWORD}`]: {
      tooltip_message: undefined,
      intent: undefined
    },
  }
  

  const { tooltip_message, intent } = vulnerabilityStatusToInfoMap[getPasswordVulnerabilityStatus()]
  
  const isPasswordAndConfirmationPasswordEqual = (password === confirmation_password)

  const hasErrorInConfirmationPassword = confirmation_password.length && !isPasswordAndConfirmationPasswordEqual

  return (
    <div className={containerClass || undefined}>
      {Header && <Header />}
      <Flash
        messages={getValuesFromObject(errors)}
        intent={Intent.DANGER}
      />
      <form autoComplete="off">
        {children}
        <FormGroup
          className={formGroupClass}
          intent={intent}
          label={<Label />}
          helperText={tooltip_message}
        >
          <InputGroup
            id="password"
            intent={intent}
            placeholder="New password"
            onChange={handleChangeInPassword}
            value={password}
            type={passwordInputType}
            autoComplete={"off"}
            rightElement={<RightElement />}
          />
        </FormGroup>
        <FormGroup
          className={formGroupClass}
          intent={hasErrorInConfirmationPassword ? "danger" : undefined}
          label="Confirm new password"
          helperText={hasErrorInConfirmationPassword ?
            "Your passwords do not match" :
            undefined
          }
        >
          <InputGroup
            id="confirmation_password"
            placeholder="Confirm new password"
            intent={hasErrorInConfirmationPassword ? "danger" : undefined}
            onChange={handleChangeInForm}
            autoComplete={"off"}
            value={confirmation_password}
            type="password"
          />
        </FormGroup>
        <Button
          type="submit"
          id="change-password-cta"
          loading={loading}
          intent="success"
          disabled={isSubmitDisabled}
          onClick={(evt) => {
            evt.preventDefault();
            onSubmit(formDetails)
          }}
        >
          {ctaLabel}
        </Button>
      </form>
    </div>
  )
}

export default ChangePassword