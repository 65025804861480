import { useHistory } from "react-router-dom";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { InputGroup, Icon, ControlGroup, Spinner } from "@blueprintjs/core";

import debounce from '../utils/debounce';
import { getQueryString, getParamsAsObject, testAttributeGenerator } from "../utils/common";

const DEFAULT_BUTTOM_GROUPS = []

const SearchInput = React.memo(
  ({
    className,
    namespace,
    ButtonGroups = DEFAULT_BUTTOM_GROUPS,
    changeInSearch,
    clearSearch,
    searchValue,
    loading,
    resetQueryFromResponse,
    searchableLabel,
    searchInputId,
    leftIcon,
    large,
    clearIcon,
    canSetParamsToUrl,
    RightButton = false,
    focusOnMount = false,
    source = '',
    RightElement = null
  }) => {
    const inputRef = useRef(null)
    const history = useHistory();
    let params = getParamsAsObject(history.location.search);
    const [localQuery, setLocalQuery] = useState("")

    const updateReduxState = useCallback(debounce((localQuery) => {
      params.search = localQuery;
      changeInSearch(localQuery)
      if (params.search.length === 0) {
        delete params.search;
      }
      if (canSetParamsToUrl) {
        setParamsToUrl(params);
      }
      if (resetQueryFromResponse) {
        resetQueryFromResponse(namespace);
      }
    }, 500), [changeInSearch])

    const handleSearchChange = (e) => {
      setLocalQuery(e.target.value)
      updateReduxState(e.target.value)
    };

    useEffect(() => {
      setLocalQuery(searchValue)
    }, [searchValue])

    useEffect(() => {
      if(focusOnMount && inputRef.current){
        inputRef.current.focus()
      }
    }, [])

    const setParamsToUrl = (params) => {
      history.replace(`${history.location.pathname}?${getQueryString(params)}`);
    };

    const handleRouteOnClearSearch = () => {
      clearSearch();
      if(canSetParamsToUrl){
        removeParamsFromUrl(params);
      }
    };

    const removeParamsFromUrl = (params) => {
      delete params.search;
      history.replace(`${history.location.pathname}?${getQueryString(params)}`);
    };

    const style = { width: "100%" };
    clearIcon = clearIcon ? clearIcon : "delete";
    return (
      <div className={className} style={style} {...testAttributeGenerator('source', source)}>
        <ControlGroup>
          <InputGroup
            fill
            id={searchInputId}
            placeholder={`${searchableLabel ? searchableLabel : "Search"}`}
            leftIcon={leftIcon ? leftIcon : "search"}
            onChange={handleSearchChange}
            intent=""
            large={large}
            value={localQuery}
            inputRef={inputRef}
            {...testAttributeGenerator('list', 'search-input')}
            rightElement={
              <div className="search-right-element">
                {loading ? (
                  <Spinner className="right-element-action" size="18" />
                ) : null}
                {RightElement}
                <Icon
                  {...testAttributeGenerator('element', 'clear-search-bar')}
                  className={`right-element-action ${
                    large ? "large" : "small"
                  }`}
                  style={{ color: "#4f5154" }}
                  icon={localQuery ? clearIcon : null}
                  onClick={handleRouteOnClearSearch}
                />
              </div>
            }
          />
          {ButtonGroups
            ? ButtonGroups.map((component_obj, index) => {
                const Button = component_obj.component;
                return <Button key={index} {...component_obj.props} />;
              })
            : null}
          {RightButton && RightButton}
        </ControlGroup>
      </div>
    );
  }
);

export default SearchInput;