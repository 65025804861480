import React, { useState, Fragment } from 'react'

import OtpInput from '../shared/components/OtpInput'
import Flash from '../shared/components/Flash'
import { Button, InputGroup, Intent } from '@blueprintjs/core'
import useToggleState from '../shared/hooks/useToggleState'
import { apiPost } from '../shared/api'
import TrustDevice from './TrustDevice'
import TagalysIcon from '../shared/components/icons/TagalysIcon'
import { ICONS } from '../shared/utils/icons'


const NUM_OF_INPUTS = 6

const containerStyle = {
  justifyContent: "center"
}

const BackUpCode = ({
  loading,
  handleFormSubmit,
  setSecondFactorMethod,
  clearErrorsIfRequired,
  errors,
  children,
  trustDevice,
  handleCheckBoxChange,
  showTrustDeviceCheckbox
}) => {
  const [backupCode, setBackupCode] = useState("")
  const handleChangeInBackupCode = (evt) => {
    setBackupCode(evt.target.value)
    clearErrorsIfRequired()
  }

  return (
    <Fragment>
      <p id="two-factor-desc" className="text-center">
          Enter the backup code
      </p>
      <InputGroup
        className="mb-xl"
        value={backupCode}
        onChange={handleChangeInBackupCode}
      />
      {children}
      {showTrustDeviceCheckbox ?
        <TrustDevice
          trustDevice = {trustDevice}
          onChange={handleCheckBoxChange}
          className="center"
        />
      :
        null
      }
      <div className="center">
        <Button
          disabled={backupCode.length === 0}
          intent="success"
          loading={loading}
          onClick={(evt) => {
            evt.preventDefault();
            handleFormSubmit("backup_code", backupCode)
          }}
          type="submit"
        >
          Submit
        </Button>
      </div>
      <div className="center flex-column alternate-auth-option">
        <h3 className='header'>
          Have access to your device?
        </h3>
        <a className="d-block" href="#" onClick={() => setSecondFactorMethod("otp")}>
          Enter 6 digit OTP
        </a>
      </div>
    </Fragment>
  )
}

const Otp = ({
  loading,
  handleFormSubmit,
  setSecondFactorMethod,
  clearErrorsIfRequired,
  errors,
  children,
  trustDevice,
  handleCheckBoxChange,
  showTrustDeviceCheckbox
}) => {
  const [otp, setOtp] = useState("")

  const handleChangeInOtp = (otp) => {
    setOtp(otp),
    clearErrorsIfRequired()
  }
  
  return (
    <Fragment>
      <p id="two-factor-desc" className="text-center">
          Enter {NUM_OF_INPUTS} digits from your Two-Factor Authenticator app
      </p>
      <OtpInput
        className="mb-20"
        inputStyle="inputStyle bp3-input"
        value={otp}
        onChange={handleChangeInOtp}
        numInputs={NUM_OF_INPUTS}
        hasError={errors.length}
        errorStyle={"bp3-intent-danger"}
        separator={<span></span>}
        containerStyle={containerStyle}
        isInputNum={true}
      />
      {children}
      {showTrustDeviceCheckbox?
        <TrustDevice
          trustDevice = {trustDevice}
          onChange={handleCheckBoxChange}
          className="center"
        />
      :
        null
      }
      <div className="center">
        <Button
          type="submit"
          loading={loading}
          intent="success"
          disabled={otp.length !== NUM_OF_INPUTS}
          onClick={(evt) => {
            evt.preventDefault();
            handleFormSubmit("otp", otp)
          }}
        >
          Submit
        </Button>
      </div>
      <div className="center flex-column alternate-auth-option">
        <h3 className='header'>
          Don't have access to your device?
        </h3>
        <a className="d-block" onClick={() => setSecondFactorMethod("backup_code")}>
          Enter a backup code
        </a>
      </div>
    </Fragment>
  )
}

export default function TwoFactorScreen({
  trustDevice,
  twoFactorPath,
  handleTwoFactorResponse,
  getOtpParams,
  showTrustDeviceCheckbox = true,
  children
}) {
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useToggleState()
  const [secondFactorMethod, setSecondFactorMethod] = useState("otp")
  const [trust_device, set_trust_device] = useState(trustDevice)
  
  const clearErrorsIfRequired = () => {
    if (errors.length) {
      setErrors([])
    }
  }

  const handleFormSubmit = async (secondFactorMethod, value) => {
    setLoading(true)
    const res = await apiPost(twoFactorPath, getOtpParams({
      secondFactorMethod,
      value,
      trust_device
    }))
    if (res) {
      handleTwoFactorResponse(res);
      if (res.errors) {
        handleFormFailure(res.errors)
      }
    }
    setLoading(false)
  }

  const handleFormFailure = (errors) => {
    setErrors(errors)
    setLoading(false)
  }

  const handleCheckBoxChange = (evt) => {
    const { checked } = evt.target
    set_trust_device(checked)
  }

  return (
    <div className="two-factor-container">
      <TagalysIcon className={`mb-xl`} icon={ICONS.LOCK} size={40}/>
      <Flash
        intent={Intent.DANGER}
        messages={errors}
        className="mb-xl"
      />
      <form className="w-100">
        {secondFactorMethod == "otp" ?
          <Otp
            loading={loading}
            setSecondFactorMethod={setSecondFactorMethod}
            handleFormSubmit={handleFormSubmit}
            clearErrorsIfRequired={clearErrorsIfRequired}
            errors={errors}
            trustDevice={trust_device}
            handleCheckBoxChange={handleCheckBoxChange}            
            showTrustDeviceCheckbox={showTrustDeviceCheckbox}
          >
            {children}
          </Otp> :
          <BackUpCode
            loading={loading}
            setSecondFactorMethod={setSecondFactorMethod}
            handleFormSubmit={handleFormSubmit}
            clearErrorsIfRequired={clearErrorsIfRequired}
            errors={errors}
            trustDevice={trust_device}
            handleCheckBoxChange={handleCheckBoxChange}       
            showTrustDeviceCheckbox={showTrustDeviceCheckbox}     
          >
            {children}
          </BackUpCode>
        }
      </form>
    </div>
  )
}