import { useImmer } from "use-immer";
import { arrayMove } from "react-sortable-hoc";

import { apiGet, apiPost } from "../api";
import useSearch from "./useSearch";
import usePagination from "./usePagination";

//NOTE:  FILTER, BULK DATA - support is not provided

export const defaultState = {
  loading: null,
  headers: [],
  items: [],
  url: '',
  requestType: '',
  // Unused props
  total: null,
  total_items: null,
  total_resource_count: null,
  page: null,
  per_page: null,
  last_selected_sort_order: null,
  last_selected_sort_column: null,
  showDrawer: false,
  errorMessage: '',
  listAvailable: null,
  additionalInfo: {},
  bulkSelectedStatus: "none",
  originalResourcePreferences: {},
  modifiedResourcePreferences: {},
  bulkSelectionMenu: {},
  bulkSelectionLoading: null,
}

const defaultFormatter = (params) => {
  return params;
};

const getListProps = (props) => {
  const {
    initialState = defaultState,
    requestFormatter = defaultFormatter,
    responseFormatter = defaultFormatter,
  } = props;
  return {
    initialState,
    requestFormatter,
    responseFormatter
  };
};

const useList = (props) => {
  const {initialState, requestFormatter, responseFormatter} = getListProps(props);
  const [resource, setResourceState] = useImmer({
    loading: false,
    url: "",
    requestType: "GET",
    list: initialState,
  });

  const pagination = usePagination();
  const search = useSearch();

  const init = async (url = null, requestType = "GET", params = {}) => {
    await setData(params, "initial_request", url, requestType);
  };

  const setData = async (
    params = {},
    dueTo = "initial_request",
    url = null,
    requestType = null
  ) => {
    setResourceState((draft) => {
      if (dueTo == "search") {
        search.setSearchLoading(true);
      }
      draft.list.loading = true;
      draft.loading = true;
    });
    let response;
    requestType = requestType ? requestType : resource.requestType;
    if (requestType === "GET") {
      response = await apiGet(url ? url : resource.url, params);
    } else {
      response = await apiPost(url ? url : resource.url, params);
    }
    response = responseFormatter(response);
    setListState(response, url, requestType);
  };

  const setListState = (data, url = null, requestType = null) => {
    setResourceState((draft) => {
      if (url) {
        draft.url = url;
      }
      if (requestType) {
        draft.requestType = requestType;
      }
      pagination.setPagination({
        perPage: data.perPage,
        currentPage: data.currentPage,
        totalPages: data.totalPages,
      });
      draft.list.items = data.items;
      draft.list.headers = data.headers;
      draft.loading = false;
      draft.list.loading = false;
      (data.search) && (search.setSearchLoading(false));
    });
  };

  const getParams = (key = null, value = null, nextState = null) => {
    let currentPage = ["search"].includes(key)
      ? 1
      : pagination.resource.pagination.currentPage;
    const stateToReferFrom = nextState ? nextState : search.resource;
    let params = {
      search: stateToReferFrom.search.query,
      page: currentPage,
      per_page: pagination.resource.pagination.perPage,
      react: true,
    };
    if (key !== null && value !== null) {
      params[key] = value;
    }
    if (requestFormatter) {
      // need to ask kiruba bro in detail about this function
      return requestFormatter(params, {
        list: resource.list,
        search: stateToReferFrom.search,
        pagination: pagination.resource.pagination,
      });
    }
    return params;
  };

  const setUrl = (url) => {
    setResourceState((draft) => {
      draft.url = url;
    });
  };

  const setLoading = (loading) => {
    setResourceState((draft) => {
      draft.list.loading = loading;
    });
  };

  const changeInSearch = (query) => {
    search.changeInSearch(query);
    setData(getParams("search", query), "search", null, "POST");
  };

  const clearSearch = () => {
    changeInSearch("");
  };

  const onPageChange = (selectedPage) => {
    pagination.onPageChange(selectedPage);
    setData(getParams("page", selectedPage), "pagination");
  };

  const onSortChange = (sortColumn) => {
    setResourceState((draft) => {
      draft.list.headers.map((header) => {
        const { field, allowed_sort_options, sorted_by } = header;
        if (field === sortColumn) {
          if (allowed_sort_options.length > 1) {
            header.sorted_by =
              sorted_by === allowed_sort_options[0]
                ? allowed_sort_options[1]
                : allowed_sort_options[0];
          } else {
            header.sorted_by = allowed_sort_options[0];
          }
          setData(
            getParams("sort", {
              field: sortColumn,
              direction: header.sorted_by,
            }),
            "sort"
          );
        } else {
          header.sorted_by = null;
        }
        return header;
      });
    });
  };

  const resetList = () => {
    search.clearSearch();
    resetListItems();
    pagination.setPagination({
      perPage: null,
      currentPage: 1,
      totalPages: null,
    });
  };

  const resetListItems = () => {
    setResourceState((draft) => {
      draft.loading = false;
      draft.url = "";
      draft.requestType = "GET";
      draft.list.items = [];
    });
  };

  const sortItems = (oldIndex, newIndex) => {
    setResourceState((draft) => {
      draft.list.items = arrayMove(draft.list.items, oldIndex, newIndex);
      return draft;
    });
  };

  return {
    data: resource,
    handlers: {
      getParams,
      onSortChange,
      setData,
      init,
      setListState,
      setLoading,
      setUrl,
      resetList,
    },
    pagination: {
      handlers: {
        onPageChange,
      },
      data: pagination.resource.pagination,
    },
    search: {
      handlers: {
        changeInSearch,
        clearSearch,
      },
      data: search.resource.search,
    },
    sort: {
      handlers: {
        sortItems: sortItems,
      },
    },
  };
};

export default useList;
