import React from "react";
import { AnchorButton, Popover, Menu, Tooltip } from "@blueprintjs/core";

import usePopup from "../hooks/usePopup";
import TagalysIcon from "./icons/TagalysIcon";
import { ICONS } from "../utils/icons";

export default React.memo(({ actions, onClickMapping }) => {
  let MainActionComponent = []
  const { inline, overflow } = actions
  const overflowActionPopup = usePopup();

  const onInteraction = (nextOpenState) => {
    if(nextOpenState !== overflowActionPopup.isOpen){
      overflowActionPopup.toggle()
    }
  }

  let inlineComponents = () => {
    if (inline) {
      return inline.map((inline_obj) => {
        return <Tooltip
          key={inline_obj.label}
          position="top"
          content={inline_obj.label}
        >
          <span className="inline-action mr-5">
            <AnchorButton
              minimal
              intent={inline_obj.intent}
              disabled={inline_obj.disabled}
              href={inline_obj.action.details && inline_obj.action.details.method === 'GET' ? inline_obj.action.details.url : null}
              onClick={onClickMapping[inline_obj.name]}
              icon={<TagalysIcon icon={inline_obj.icon} className="action-icon" />}
            />
          </span>
        </Tooltip>
      })
    }
    return null
  }

  let overflowComponents = () => {
    if (overflow) {
      return (
        <Popover
          key={'overflow'}
          position="bottom"
          className="inline-action"
          isOpen={overflowActionPopup.isOpen}
          onInteraction={onInteraction}
          content={
            <Menu className="overflow-actions" onClick={overflowActionPopup.close}>
              {
                overflow.map((overflow_obj) => {
                  return (
                    <div key={overflow_obj.name} className="flex-row">
                      <AnchorButton
                        minimal
                        fill
                        loading={overflow_obj.loading}
                        icon={<TagalysIcon icon={overflow_obj.icon ? overflow_obj.icon : null}/>}
                        intent={overflow_obj.intent}
                        disabled={overflow_obj.disabled}
                        onClick={onClickMapping[overflow_obj.name]}
                        className={!overflow_obj.loading && "justify-start"}
                      >
                        {overflow_obj.label}
                      </AnchorButton>
                    </div>
                  )
                })
              }
            </Menu>
          }
        >
          <AnchorButton
            intent="none"
            minimal
            icon={<TagalysIcon icon={ICONS.DOT_VERTICAL} className="action-icon" />}
          />
        </Popover>
      )
    }
    return null
  }
  MainActionComponent.push(inlineComponents())

  MainActionComponent.push(overflowComponents())

  return MainActionComponent
});
