import React from 'react';

import useAppData from '../hooks/useAppData';
import Flash from "./Flash"

const FlashCard = ({
  className = ""
}) => {
  const flashDetails = useAppData("flash_details")
  if (flashDetails) {
    return <Flash
      messages={flashDetails.messages}
      intent={flashDetails.intent}
      className={className}
    />
  }
  return null;
}
export default FlashCard