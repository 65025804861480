import React from "react";
import { Icon, Spinner, Button, NonIdealState } from "@blueprintjs/core";

import Rows from "./Rows";
import Header from "./Header";
import { testAttributeGenerator } from "../../utils/common";

import "../../styles/table.scss";

const Table = React.memo(function Table(props) {

  if (props.rows.length === 0 && props.loading) {
    return (
      <div className="p-20" >
        <Spinner size="35" />
      </div>
    );
  }

  if (props.rows.length === 0 && props.nonIdealStateProps) {
    const {
      header,
      description,
      buttonName,
      onClick,
      icon,
      showNonIdealStateButton = true,
    } = props.nonIdealStateProps;
    return (
      <div className="p-20">
        <NonIdealState
          icon={<Icon icon={icon} iconSize="50" />}
          title={header}
          description={description}
          action={
            showNonIdealStateButton ? (
              <Button onClick={onClick} intent="primary">
                {buttonName}
              </Button>
            ) : null
          }
        />
      </div>
    );
  }

  return (
    <table width="100%">
      <thead>
        <tr
          className="table-header"
          {...testAttributeGenerator("table-loading", props.loading)}
        >
          <Header
            headers={props.headers}
            loading={props.loading}
            onSortChange={props.onSortChange}
            canSetParamsToUrl={props.canSetParamsToUrl}
          />
        </tr>
      </thead>
      <tbody>
        <Rows
          rows={props.rows}
          headers={props.headers}
          columnDisplayOverride={props.columnDisplayOverride}
          loading={props.loading}
        />
      </tbody>
    </table>
  );
});

export default Table;