import React, { Fragment, memo, useEffect, useState } from "react";
import { Spinner, OverlayToaster, Button } from "@blueprintjs/core";
import { useImmer } from 'use-immer';
import { useHistory } from "react-router-dom";
import { Heading, Description, SpinnerContainer } from "./StyledComponents";
import EnableTwoFactor from "./EnableTwoFactor";
import { apiPost } from '../../shared/api'
import BackupCodesDialog from "./BackupCodesDialog";
import Card from "../../shared/components/Card";
import ResourceDestroyAlert from "../../shared/components/ResourceDestroyAlert";
import { getParamsAsObject } from "../../shared/utils/common";
import TagalysIcon from "../../shared/components/icons/TagalysIcon";
import { ICONS } from "../../shared/utils/icons";
import ConfirmationPrompt from "../../shared/prompts/ConfirmationPrompt";

const userToast = OverlayToaster.create({
  position: 'top'
})

export default memo(function TwoFactorAuthTab() {
  let [state, setState] = useImmer({
    loading: false,
    isTwoFactorEnabled: false,
    regenerateButtonLoading: false,
    disableButtonLoading: false,
    backupCodesDetails: {
      showBackupCodes: false,
      backUpCodes: [],
    },
    isEnforceTwoFactorInfoDialogOpen: false,
  })
  const history = useHistory()
  const { loading, isTwoFactorEnabled, backupCodesDetails, regenerateButtonLoading, disableButtonLoading } = state
  useEffect(() => {
    const urlParams = getParamsAsObject(history.location.search)
    if (urlParams.hasOwnProperty("enforce") && urlParams.enforce === "true") {
      openEnforceTwoFactorInfoDialog()
    }
    handleInit()
  }, [])

  const closeEnforceTwoFactorInfoDialog = () => {
    setState((draft) => {
      draft.isEnforceTwoFactorInfoDialogOpen = false
    })
  }

  const openEnforceTwoFactorInfoDialog = () => {
    setState((draft) => {
      draft.isEnforceTwoFactorInfoDialogOpen = true
    })
  }

  async function handleInit() {
    setState(draft => { draft.loading = true })
    const response = await apiPost("two_factor_authentication/is_2fa_enabled", {})
    if (response) {
      setState(draft => { draft.isTwoFactorEnabled = response.enabled })
    }
    setState(draft => { draft.loading = false })
  }

  const handleTwoFactorDisable = async () => {
    setState(draft => { draft.disableButtonLoading = true })
    const response = await apiPost("two_factor_authentication/disable_2fa", {});
    if (response) {
      if (response.disable) {
        userToast.show({
          message: "Two-factor Authentication has been disabled.",
          intent: 'success',
          timeout: 3000,
          icon: <TagalysIcon icon={ICONS.CHECK} />
        })
        setState(draft => { draft.isTwoFactorEnabled = false })
      }
      else {
        userToast.show({
          message: "Unable to disable Two-factor Authentication.",
          intent: 'danger',
          timeout: 3000
        })
      }
    }
    setState(draft => { draft.disableButtonLoading = false })
  }

  const handleRegenerateCodes = async () => {
    setState(draft => { draft.regenerateButtonLoading = true })
    const response = await apiPost("two_factor_authentication/regenerate_backup_codes", {});
    if (response) {
      if (response.saved) {
        userToast.show({
          message: 'Backup codes have been generated successfully',
          intent: 'success',
          timeout: 3000
        })
        setBackupCodeDetails(response.backup_codes, 'regenerate_backup_codes')
      }
      else {
        userToast.show({
          message: "Unable to generate backup codes.",
          intent: 'danger',
          timeout: 3000
        })
      }
    }
    setState(draft => { draft.regenerateButtonLoading = false })
  }

  const setBackupCodeDetails = (backupCodes, actionType) => {
    if (actionType === 'enabled_two_factor') {
      setState(draft => {
        draft.isTwoFactorEnabled = true;
        draft.backupCodesDetails = { showBackupCodes: true, backUpCodes: backupCodes };
      })
    }
    else {
      setState(draft => {
        draft.regenerateButtonLoading = false;
        draft.backupCodesDetails = { showBackupCodes: true, backUpCodes: backupCodes }
      })
    }
  }

  const resetBackupDetails = () => {
    setState(draft => {
      draft.backupCodesDetails = {
        showBackupCodes: false,
        backUpCodes: [],
      }
    })
  }
  
  if (loading) {
    return (
      <SpinnerContainer>
        <Spinner size="50" />
      </SpinnerContainer>
    )
  }
  else {
    return (
      <Fragment>
        <Heading>Two-Factor Authentication</Heading>
        <Description className="mb-lg">
          An authenticator application that supports TOTP (Time based One Time Password) can be used to conveniently secure your account. A new token is generated every 30 seconds.
        </Description>
        <TwoFactorContent
          isTwoFactorEnabled={isTwoFactorEnabled}
          regenerateButtonLoading={regenerateButtonLoading}
          handleRegenerateCodes={handleRegenerateCodes}
          handleTwoFactorDisable={handleTwoFactorDisable}
          disableButtonLoading={disableButtonLoading}
          setBackupCodeDetails={setBackupCodeDetails}
        />
        <BackupCodesDialog
          backupCodesDetails={backupCodesDetails}
          resetBackupDetails={resetBackupDetails} 
        />
        <ConfirmationPrompt
          confirmButtonText={"Continue"}
          icon={ICONS.INFO_CIRCLE}
          isOpen={state.isEnforceTwoFactorInfoDialogOpen}
          onConfirm={closeEnforceTwoFactorInfoDialog}
          title={"Your organisation has enforced Two-Factor Authentication for your account."}
          description={"Please enable now to continue using the dashboard."}
        />
      </Fragment>
    )
  }
})
const TwoFactorContent = ({
  isTwoFactorEnabled,
  regenerateButtonLoading,
  handleRegenerateCodes,
  handleTwoFactorDisable,
  disableButtonLoading,
  setBackupCodeDetails
}) => {
  if (isTwoFactorEnabled) {
    return (
      <Fragment>
        <Heading className="two-factor-enabled-header">Two-Factor Authentication is enabled.</Heading>
        <div className="flex-column">
          <RegenerateBackupCodes
            regenerateButtonLoading={regenerateButtonLoading}
            handleRegenerateCodes={handleRegenerateCodes}
          />
          <DisableTwoFactor
            handleTwoFactorDisable={handleTwoFactorDisable}
            disableButtonLoading={disableButtonLoading}
          />
        </div>
      </Fragment>
    )
  }
  return <EnableTwoFactor setBackupCodeDetails={setBackupCodeDetails} />
}

const RegenerateBackupCodes = ({
  regenerateButtonLoading,
  handleRegenerateCodes
}) => {
  return (
    <Card className="regenerate">
      <h4>Regenerate Backup Codes</h4>
      <div className="sub-info">
        Backup codes can be used only once. Regenerating the backup codes will invalidate the previous codes and generate a new set of codes.
      </div>
      <Button
        intent='primary'
        loading={regenerateButtonLoading}
        onClick={handleRegenerateCodes}>
        Regenerate Backup Codes
      </Button>
  </Card>
  )
}

const DisableTwoFactor = ({
  handleTwoFactorDisable,
  disableButtonLoading
}) => {
  const [isAlertOpen, toggleAlert] = useState(false);
  const openAlert = () => {
    toggleAlert(true)
  }
  const closeAlert = () => {
    toggleAlert(false)
  }
  return (
    <Fragment>
      <Card className="disable-two-factor">
        <h4>Disable Two-Factor Authentication</h4>
        <div className="sub-info">
          It is recommended to have Two-Factor Authentication to keep the accounts secure. With this even if someone else gets your password, it won't be enough to log in to your account.
        </div>
        <Button
          intent='danger'
          loading={disableButtonLoading}
          onClick={openAlert}>
          Disable
        </Button>
      </Card>
      <ResourceDestroyAlert
        icon={ICONS.SLASH_CIRCLE}
        cancelButtonText="Cancel"
        confirmButtonText="Disable"
        isOpen={isAlertOpen}
        handleCancel={closeAlert}
        handleConfirm={() => {
          handleTwoFactorDisable()
          closeAlert();
        }}
        header="Are you sure you want to disable the Two-Factor Authentication?"
      />
    </Fragment>
  )
}