import React, { useState } from 'react'

export const AuthorisationContext = React.createContext()

export default function AuthorizationProvider(props) {
  const [isDialogOpen, toggleDialogState] = useState(false)
  return (
    <AuthorisationContext.Provider value={ [isDialogOpen, toggleDialogState] }>
      {props.children}
    </AuthorisationContext.Provider>
  )
}
